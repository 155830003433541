<template>
  <div class="view-fees-paid">
    <div>
      <v-row class="mx-3 mt-2">
        <!-- <v-col class="text-left">
          <v-checkbox v-model="printTwoCopies" label="Print 2 Copies"></v-checkbox>
        </v-col> -->
        <v-col class="text-right">
          <v-btn elevation="1" color="white" v-if="!isLoading && receipt != null && receipt.is_latest && currentUser.privileges.canDeleteStudentFeeReceipts" @click="showDeleteConfirmation = true" class="mr-2">Delete</v-btn>
          <v-btn elevation="1" color="primary" v-if="!isLoading" @click="printPdf" class="mr-2">
            <v-icon>mdi-printer</v-icon>
            PRINT
          </v-btn>
          <v-btn elevation="1" color="primary" v-if="!isLoading" @click="emailReceipt">EMAIL</v-btn>
          <v-progress-circular v-else indeterminate color="primary"></v-progress-circular>
        </v-col>
      </v-row>
      <FeeReceiptTable id="fee-receipt-table" :receipt="receipt" :copyName="`STUDENT COPY`"/>
      <!-- <FeeReceiptTable v-if="printTwoCopies" id="fee-receipt-table" :receipt="receipt" :copyName="`${printTwoCopies ? 'PARENT COPY' : ''}`" /> -->
    </div>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" multiline>
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.visible = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
     <!-- <DeleteConfirmationDialog
            title="Are you sure you want to delete this recently created Fee Receipt?"
            description="This action cannot be undone."
            :visible="showDeleteConfirmation"
            @deleteObject="deleteReceipt"
        /> -->
  </div>
  
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import endpoints from "@api/repository";
import api from "@api/index";
import FeeReceiptTable from "@components/fees/FeeReceiptTable";
// import DeleteConfirmationDialog from "@components/widgets/dialogs/DeleteConfirmationDialog";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";

export default {
  name: "ViewFeesPaid",
  data() {
    return {
      receipt: null,
      showDeleteConfirmation: false,
      snackbar: {
        visible: false,
        text: "",
        color: 'green',
      },

    };
  },
  components: {
    FeeReceiptTable
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentInstitution",
      "accessToken",
      "isLoading",
    ]),
    essentials() {
      return {
        accessToken: this.accessToken,
        setLoading: this.setLoading,
        handleErrorsFunction: this.handleApiErrors,
      };
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getReceiptData() {
      this.receipt = await api.call(
        this.essentials,
        endpoints.getFeeReceiptDetails + this.$route.params.rid + "/"
      );
      console.log(this.receipt);
    },

    async emailReceipt(){
        var response = await api.call(
          this.essentials,
          endpoints.getFeeReceiptDetails + "email/" + this.$route.params.rid + "/"
      );
      if(response && response == 200){
        this.snackbar.text = 'Email has been sent successfully'
        this.snackbar.color = 'success'
        this.snackbar.visible = true
      }
      else{
        this.snackbar.text = 'User e-mail not found'
        this.snackbar.color = 'error'
        this.snackbar.visible = true
      }
    },

    async deleteReceipt(confirm) {
        this.showDeleteConfirmation = false;
        var url = endpoints.undoFeeReceipt;
        if (confirm) {
            var response;
            response = await api.handleApi({
                apiEssentials: this.essentials,
                url: url,
                method: api.Methods.DELETE,
                responseVar: response,
            });
            if (response) {
                this.$router.push({ name: "StudentFeeDetails", params: {sid: this.receipt.fee_account.student.id}});
            }
        }
    },


    async printPdf() {
      var windowOptions =
        "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=1024,height=768";
      window.open(
        "/print/" +
          // this.$route.params.sid +
          // "/" +
          this.$route.params.rid +
          "/" +
          "1",
        "Print Receipt",
        windowOptions

      );
    },
  },
  created() {
    this.getReceiptData();
  },
};
</script>